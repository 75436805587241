var exports = {};
exports = makeReflectTypes; //Construct type info for reflection.
//
// This iterates over the flattened list of uniform type values and smashes them into a JSON object.
//
// The leaves of the resulting object are either indices or type strings representing primitive glslify types

function makeReflectTypes(uniforms, useIndex) {
  var obj = {};

  for (var i = 0; i < uniforms.length; ++i) {
    var n = uniforms[i].name;
    var parts = n.split(".");
    var o = obj;

    for (var j = 0; j < parts.length; ++j) {
      var x = parts[j].split("[");

      if (x.length > 1) {
        if (!(x[0] in o)) {
          o[x[0]] = [];
        }

        o = o[x[0]];

        for (var k = 1; k < x.length; ++k) {
          var y = parseInt(x[k]);

          if (k < x.length - 1 || j < parts.length - 1) {
            if (!(y in o)) {
              if (k < x.length - 1) {
                o[y] = [];
              } else {
                o[y] = {};
              }
            }

            o = o[y];
          } else {
            if (useIndex) {
              o[y] = i;
            } else {
              o[y] = uniforms[i].type;
            }
          }
        }
      } else if (j < parts.length - 1) {
        if (!(x[0] in o)) {
          o[x[0]] = {};
        }

        o = o[x[0]];
      } else {
        if (useIndex) {
          o[x[0]] = i;
        } else {
          o[x[0]] = uniforms[i].type;
        }
      }
    }
  }

  return obj;
}

export default exports;