var exports = {};
exports.uniforms = runtimeUniforms;
exports.attributes = runtimeAttributes;
var GL_TO_GLSL_TYPES = {
  "FLOAT": "float",
  "FLOAT_VEC2": "vec2",
  "FLOAT_VEC3": "vec3",
  "FLOAT_VEC4": "vec4",
  "INT": "int",
  "INT_VEC2": "ivec2",
  "INT_VEC3": "ivec3",
  "INT_VEC4": "ivec4",
  "BOOL": "bool",
  "BOOL_VEC2": "bvec2",
  "BOOL_VEC3": "bvec3",
  "BOOL_VEC4": "bvec4",
  "FLOAT_MAT2": "mat2",
  "FLOAT_MAT3": "mat3",
  "FLOAT_MAT4": "mat4",
  "SAMPLER_2D": "sampler2D",
  "SAMPLER_CUBE": "samplerCube"
};
var GL_TABLE = null;

function getType(gl, type) {
  if (!GL_TABLE) {
    var typeNames = Object.keys(GL_TO_GLSL_TYPES);
    GL_TABLE = {};

    for (var i = 0; i < typeNames.length; ++i) {
      var tn = typeNames[i];
      GL_TABLE[gl[tn]] = GL_TO_GLSL_TYPES[tn];
    }
  }

  return GL_TABLE[type];
}

function runtimeUniforms(gl, program) {
  var numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
  var result = [];

  for (var i = 0; i < numUniforms; ++i) {
    var info = gl.getActiveUniform(program, i);

    if (info) {
      var type = getType(gl, info.type);

      if (info.size > 1) {
        for (var j = 0; j < info.size; ++j) {
          result.push({
            name: info.name.replace("[0]", "[" + j + "]"),
            type: type
          });
        }
      } else {
        result.push({
          name: info.name,
          type: type
        });
      }
    }
  }

  return result;
}

function runtimeAttributes(gl, program) {
  var numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
  var result = [];

  for (var i = 0; i < numAttributes; ++i) {
    var info = gl.getActiveAttrib(program, i);

    if (info) {
      result.push({
        name: info.name,
        type: getType(gl, info.type)
      });
    }
  }

  return result;
}

export default exports;