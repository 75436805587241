var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function GLError(rawError, shortMessage, longMessage) {
  (this || _global).shortMessage = shortMessage || "";
  (this || _global).longMessage = longMessage || "";
  (this || _global).rawError = rawError || "";
  (this || _global).message = "gl-shader: " + (shortMessage || rawError || "") + (longMessage ? "\n" + longMessage : "");
  (this || _global).stack = new Error().stack;
}

GLError.prototype = new Error();
GLError.prototype.name = "GLError";
GLError.prototype.constructor = GLError;
exports = GLError;
export default exports;